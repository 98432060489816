
import { Component, Vue } from 'vue-property-decorator';
import SearchIcon from './icons/SearchIcon.vue';
import MobileHeaderSearchOverlayModal from './MobileHeaderSearchOverlayModal.vue';

@Component({
    name: 'MobileHeaderSearchOverlay',
    components: {
        SearchIcon,
        MobileHeaderSearchOverlayModal,
    },
})
export default class MobileHeaderSearchOverlay extends Vue {
    private displayOverlay: boolean = false;

    private toggleSearch() {
        // CP uses external search provider. Disable default search overlay
        return;
        // this.displayOverlay = !this.displayOverlay;
        // if (this.displayOverlay) {
        //     // iOS zooms on input with font-size smaller than 16px, prevent this on display overlay
        //     const viewportmeta = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
        //     viewportmeta.content = 'user-scalable=NO, width=device-width, initial-scale=1.0';
        //     document.body.style.overflow = 'hidden';
        // } else {
        //     const viewportmeta = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
        //     viewportmeta.content = 'width=device-width, initial-scale=1.0';
        //     document.body.style.overflow = null;
        // }
    }
}
